/* Home Styles */
.scrollContainer  {
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    height: 100%;
}
.header  {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    position: absolute;
    width: calc(100% - 60px);
    background-color: var(--background);
    z-index: 999;
}
.headerLogo  {
    width: 35px;
    margin-right: 10px;
}
.headerIconLink  {
    outline: none;
    text-decoration: none;
    color: var(--text-color);
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}
.headerIconLink:hover  {
    opacity: 0.6;
    transform: scaleX(1.1);
    cursor: pointer;
}
.headerIcon  {
    font-size: 30px;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}
.headerLinkContainer  {
    display: flex;
    flex-direction: row;
}
.headerLink  {
    text-transform: uppercase;
    font-size: 15px;
    margin: 0 10px;
    font-weight: 900;
    text-decoration: none;
    color: var(--text-color);
    outline: none;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}
.headerLink:hover  {
    opacity: 0.6;
    transform: scaleX(1.05);
}
.scrollSubcontainer  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100%);
    min-height: calc(100%);
    scroll-snap-align: start;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-snap-align: start;
}
.landingSubcontainer  {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.landingYear, .missionTitle  {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px 10px;
    background-color: var(--text-color);
    color: var(--background);
}
.missionTitle2{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 30px;
    padding: 5px 10px;
    background-color: var(--text-color);
    color: var(--background);
}
.landingTitle  {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 80px;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

.profileName  {
    font-weight: 900;
    font-size: 60px;
    margin: 0px;
    padding: 0px;
    text-align: center;
}
.landingSubtitle  {
    font-weight: 600;
    font-size: 25px;
    margin-top: 1em;
    text-align: center;
    font-family: "Graphik Light";
    width: 60%;
}
.landingIcon  {
    margin-bottom: 1em;
    animation: jiggle-icon 2s ease-in-out infinite;
    transition: all 0.5s cubic-bezier(.075, .82, .165, 1);
}
.missionSubContainer  {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.missionItemsContainer  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.missionItemContainer  {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
    align-items: center;
}
.missionItemImage  {
    height: 10vh;
    margin: 0 25px; 
}
.missionItemDescription  {
    margin-top: 1em;
    text-align: center;
    font-family: "Graphik Regular";
}
.subtext {
    margin-left: 150px;
    margin-top: 25px;
    font-size: 20px;
    margin-right: 150px;
    text-align: center;
}
.horizontalLine {
    border: 0;
    margin-top: 20px;
    border-top: 5px dotted;
    width: 50%;
}
.imageContainer {
    display: inline-block;
    margin-top: 10px;
    gap: 5000px;
    justify-content: left;
  }
.container {
    position: relative;
    height: 100vh; /* Full height of the viewport */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
  }
  
.hover-button {
    display: inline-block; /* Make anchor behave like a block */
    background-color: #4CAF50; /* Green background */
    color: white;          /* White text */
    padding: 15px 32px;    /* Padding for the button */
    border: none;          /* Remove border */
    font-size: 16px;       /* Font size */
    text-align: center;    /* Center text */
    text-decoration: none; /* Remove underline */
    border-radius: 5px;    /* Optional: rounded corners */
    cursor: pointer;       /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
.hover-button:hover {
    background-color: #45a049; /* Change color on hover */
  }

.body {
    font-family: 'Poppins', sans-serif;
    display: grid;
    height: 100vh;
    place-items: center;
    background-color: black;
    color: white;
}

.container {
    width: 100%;
    height: 100vh; /* Adjusted from min-height for consistent layout inside scrollSubcontainer */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centers content vertically */
    box-sizing: border-box;
}

/* Title Styling */
.container h2 {
    color: #fff;
    font-size: 32px;
    margin: 10 0 50px; /* Replaced padding for more precise spacing */
    text-align: center;
    margin-top: 100px;
}

/* Pricing Row */
.priceRow {
    width: 90%;
    max-width: 1100px;
    margin: auto; /* Center-aligns the grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 25px; /* Replaced grid-gap for modern syntax */
}

/* Individual Price Column */
.priceCol{
    width: 250px;  /* Fixed width */
    padding: 20px;
    background: #1f283b;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    height: auto;  /* Height will adjust based on content */
}

.priceCol:hover {
    transform: translateY(-10px); /* Interactive hover effect */
}

/* Price Column Text */
.priceCol p {
    font-size: 22px;
}

.priceCol h3 {
    font-size: 44px;
    margin: 20px 0 40px;
    font-weight: 500;
}

.priceCol h3 span {
    font-size: 16px;
}

/* Features List */
.priceCol ul {
    text-align: left;
    margin: 20px;
    color: #ddd;
    list-style: none;
}

.priceCol ul li {
    margin: 15px 0;
}

.priceCol ul li.positive::before {
    content: "✔"; /* Unicode for checkmark */
    color: green; /* Green color for checkmark */
    font-weight: bold;
    margin-right: 10px;
}

.priceCol ul li.negative::before {
    content: "✘"; /* Unicode for X */
    color: red; /* Red color for X */
    font-weight: bold;
    margin-right: 10px;
}


  
/* For the Payment Form Container */
.paymentDetailsContainer {
    width: 100%;
    max-width: 600px; /* Adjust the max width for the container */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 40px;
  }
  
  /* Container that ensures the form is centered on the page */
.scrollSubcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Horizontally center */
    justify-content: center;  /* Vertically center */
    height: 100vh;  /* Full viewport height */
    padding: 20px;
  }
  
.missionTitle {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 30px;
    padding: 5px 10px;
    background-color: var(--text-color);
    color: var(--background);
    margin-bottom: 30px;
  }
  
.paymentFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 500px; /* Form width */
    box-sizing: border-box;
  }
  
.cardElementContainer {
    width: 100%;
    margin-bottom: 20px;
  }
  
  /* Styling for the Button */
.hoverButton {
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 15px 32px;
    border: none;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.hoverButton:hover {
    background-color: #45a049; /* Change color on hover */
  }
  
  button:disabled {
    background-color: #bbb;
  }
  
  /* Error & Success Messages */
.error, .success {
    margin-top: 15px;
    font-size: 16px;
    color: #d32f2f; /* red for error */
    font-weight: 600;
  }
  
.success {
    color: #388e3c; /* green for success */
  }

  
footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

/* Styling for footer links */
footer a {
    color: #ffcc00;
    text-decoration: none;
    margin: 0 10px;
}

footer a:hover {
    text-decoration: underline;
}

  

/* Animations */
@keyframes jiggle-icon {
    0% {
        transform: translateY(0px);
    }

    45% {
        transform: translateY(5px);
    }
    60% {
        transform: translateY(0px);
    }
    75% {
        transform: translateY(5px);
    }
}


.marquee-contain  {
    display: flex;
    overflow: hidden;
}
.marquee-sub-contain  {
    display: flex;
    white-space: nowrap;
    animation: infinite-scroll 15s linear infinite;
    animation-play-state: running;
    will-change: transform;
}
@keyframes infinite-scroll {
    0% {
        transform: translate3d(calc(0% * -1),0,0);
    }

    to {
        transform: translate3d(calc(-100% - 0%),0,0);
    }
}
